<template>
  <div>
    <v-row>
      <v-col md="3" style="border: 1px solid gray" :key="componentKey">
        <v-text-field
          v-model="search"
          label="Suche..."
          flat
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
        <v-treeview
          v-if="FrontendProductstructure"
          :items="FrontendProductstructure"
          hoverable
          open-on-click
          :search="search"
          :filter="filter"
          :active.sync="active"
          :open.sync="open"
          activatable
        >
          <template v-slot:prepend="{ item }">
            <v-chip
              v-if="!item.children"
              class="ma-2"
              :color="
                item.name.substring(2, 3).toUpperCase() === 'B'
                  ? 'indigo'
                  : 'red'
              "
              text-color="white"
            >
              {{
                item.name.substring(2, 3).toUpperCase() === "B"
                  ? "base"
                  : "peak"
              }}
            </v-chip>
          </template>
        </v-treeview>
      </v-col>
      <v-col md="9" style="border: 1px solid grey" >
        <v-container fluid>
          <v-row v-if="active.length > 0" dense>
            <v-col
              v-for="risktype in Aufschlagskategorien"
              :key="risktype.index"
              cols="6"
            >
              <v-card>
                <!--<v-card-title v-text="risktype"></v-card-title>-->
                <v-card-text>
                  <v-simple-table height="300px" dense>
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>{{ risktype }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                      </v-toolbar>
                    </template>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ tabellenHeaderNachKategorie[risktype].Beschreibung}}</th>
                          <th class="text-left">{{ tabellenHeaderNachKategorie[risktype].Wert}}</th>
                          <!--<th class="text-left">Typ</th>-->
                          <th></th>
                        </tr>
                      </thead>
                      <tbody :key="dataKey">
                        <tr
                          v-for="(
                            aufschlag, index
                          ) in CurrentProductAufschlaege[risktype]"
                          :key="index"
                        >
                          <td>
                            <v-icon
                              v-if="aufschlag.showtype == 'kategorie'"
                              color="orange darken-2"
                              >mdi-arrow-up-bold-box-outline</v-icon
                            >
                            {{ aufschlag.beschreibung }}
                          </td>
                          <td>{{ aufschlag.wert }}</td>
                          <!--<td>{{ aufschlag.showtype }}</td>-->
                          <td>
                            <v-icon
                              v-if="
                                activeParsed.produkttyp === 'kategorien' ||
                                (activeParsed.produkttyp === 'produkte' &&
                                  aufschlag.showtype === 'produkt')
                              "
                              small
                              class="mr-2"
                              @click="editAufschlag(aufschlag)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              v-if="
                                activeParsed.produkttyp === 'kategorien' ||
                                (activeParsed.produkttyp === 'produkte' &&
                                  aufschlag.showtype === 'produkt')
                              "
                              small
                              @click="deleteAufschlag(aufschlag)"
                            >
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"></td>
                          <td>
                            <v-icon small @click="addNewAufschlag(risktype)">
                              mdi-plus</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" width="800" v-if="editAufschlag_item">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Produkt bearbeiten
        </v-card-title>

        <v-card-text style="padding-top: 10px">
          <v-text-field
            type="text"
            label="Beschreibung"
            v-model="editAufschlag_item.beschreibung"
          ></v-text-field>
          <v-text-field
            type="text"
            label="Wert"
            v-model="editAufschlag_item.wert"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveAufschlag"> Speichern </v-btn>
          <v-btn color="primary" text @click="abortSaveAufschlag">
            Abbruch
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { firestore } from "@/plugins/firebase"
import {
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    deleteDoc,
    query
} from "@firebase/firestore"

export default {
    data() {
        return {
            tabellenHeaderNachKategorie: {
                "Risiko": {
                    "Beschreibung": "Beschreibung",
                    "Wert": "Wert in €"
                },
                "Typ": {
                    "Beschreibung": "Beschreibung",
                    "Wert": "Wert in €"
                },
                "Zeit": {
                    "Beschreibung": "Delta in Sek.",
                    "Wert": "Wert in €"
                }

            },
            FrontendProductstructure: null,
            CurrentProductAufschlaege: {},
            Aufschlagskategorien: [],
            componentKey: 0,
            dataKey: 9000,
            active: [],
            activeParsed: [],
            search: null,
            open: [1, 2],
            editDialog: false,
            editAufschlag_item: null,
            editAufschlag_itemInitial: null
        }
    },
    computed: {
        filter() {
            return (item, search, textKey) => item[textKey].indexOf(search) > -1
        }
    },
    methods: {
        editAufschlag(aufschlag) {
            this.editAufschlag_itemInitial = JSON.parse(JSON.stringify(aufschlag))
            this.editAufschlag_item = aufschlag
            this.editDialog = true
        },
        abortSaveAufschlag() {
            this.editAufschlag_item.wert = this.editAufschlag_itemInitial.wert
            this.editAufschlag_item.beschreibung =
        this.editAufschlag_itemInitial.beschreibung
            this.editDialog = false
        },
        async deleteAufschlag(aufschlag) {
            let path =
        "/produktaufschlaege/" +
        this.activeParsed.produkttyp +
        "/anbieter/" +
        this.activeParsed.anbieter +
        "/produkte/" +
        this.activeParsed.produkt +
        "/aufschlaege"

            let coll = collection(firestore, path)
            let docRef = doc(coll, aufschlag.id)
            await deleteDoc(docRef)
            this.fetchProductAufschlaege(this.active)
            this.dataKey++
        },
        async saveAufschlag() {
            let coll = collection(
                firestore,
                "/produktaufschlaege/" +
          this.activeParsed.produkttyp +
          "/anbieter/" +
          this.activeParsed.anbieter +
          "/produkte/" +
          this.activeParsed.produkt +
          "/aufschlaege"
            )
            let docRef = this.editAufschlag_item.id
                ? doc(coll, this.editAufschlag_item.id)
                : doc(coll)
            let dataset = {
                wert: this.editAufschlag_item.wert,
                beschreibung: this.editAufschlag_item.beschreibung,
                kategorie: this.editAufschlag_item.kategorie
            }
            await setDoc(docRef, dataset)
            this.fetchProductAufschlaege(this.active)
            this.dataKey++
            this.editDialog = false
        },
        addNewAufschlag(kategorie) {
            this.editAufschlag({
                wert: 0,
                beschreibung: "",
                kategorie: kategorie
            })
        },

        async fetchProductAufschlaege(productJson) {
            this.CurrentProductAufschlaege = {}
            let p = JSON.parse(productJson)

            if (p.produkttyp == "produkte") {
                let product = p.produkt
                let baseproduct = product.substring(0, 4)
                let snapShotKategorie = await getDocs(
                    query(
                        collection(
                            firestore,
                            "/produktaufschlaege/" +
                "kategorien/" +
                "anbieter/" +
                p.anbieter +
                "/produkte/" +
                baseproduct +
                "/aufschlaege"
                        )
                    )
                )
                for (let doc of snapShotKategorie.docs) {
                    let el = doc.data()
                    el.showtype = "kategorie"
                    el.id = doc.id
                    if (this.CurrentProductAufschlaege[el.kategorie] === undefined) {
                        this.CurrentProductAufschlaege[el.kategorie] = []
                    }
                    this.CurrentProductAufschlaege[el.kategorie].push(el)
                }
            }

            let snapShot = await getDocs(
                query(
                    collection(
                        firestore,
                        "/produktaufschlaege/" +
              p.produkttyp +
              "/anbieter/" +
              p.anbieter +
              "/produkte/" +
              p.produkt +
              "/aufschlaege"
                    )
                )
            )
            for (let doc of snapShot.docs) {
                let el = doc.data()
                el.showtype = "produkt"
                el.id = doc.id
                if (this.CurrentProductAufschlaege[el.kategorie] === undefined) {
                    this.CurrentProductAufschlaege[el.kategorie] = []
                }
                this.CurrentProductAufschlaege[el.kategorie].push(el)
            }
            this.dataKey++
        }
    },
    async mounted() {
    // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }

        // Lade Aufschlagstypen
        let snapShot = await getDoc(
            doc(firestore, "/Einstellungen/Aufschlagskategorien")
        )
        let data = snapShot.data()
        let AufschlagskategorienRaw = data.Kategorien
        AufschlagskategorienRaw.shift()
        this.Aufschlagskategorien = data.Kategorien

        //Lade Anbieter
        snapShot = await getDoc(
            doc(firestore, "/Einstellungen/FrontendProductstructure")
        )
        data = snapShot.data()

        let pId = 1
        let basisprodukte = []
        for (let anbieter in data.basisprodukte.anbieter) {
            if (anbieter === "Montel") { continue;}
            let produkte = data.basisprodukte.anbieter[anbieter]
            let _bp_anbieter = {
                name: anbieter,
                children: [],
                id: pId++
            }

            for (let produkt of produkte) {
                _bp_anbieter.children.push({
                    name: produkt,

                    id: JSON.stringify({
                        produkttyp: "kategorien",
                        anbieter: anbieter,

                        produkt: produkt
                    })
                })
            }

            basisprodukte.push(_bp_anbieter)
        }

        let produkte = []
        for (let anbieter in data.produkte.anbieter) {
            if (anbieter === "Montel") { continue;}
            let jahre = data.produkte.anbieter[anbieter]

            let _p_anbieter = {
                name: anbieter,
                children: [],
                id: pId++
            }

            for (let jahr in jahre) {
                let lieferintervalle = jahre[jahr]

                let _p_anbieter_jahr = {
                    name: jahr,
                    children: [],
                    id: pId++
                }

                for (let lieferintervall in lieferintervalle) {
                    let _produkte = lieferintervalle[lieferintervall]

                    let _p_anbieter_jahr_lieferintervall = {
                        name: lieferintervall,
                        children: [],
                        id: pId++
                    }

                    for (let produkt of _produkte) {
                        _p_anbieter_jahr_lieferintervall.children.push({
                            name: produkt,
                            id: JSON.stringify({
                                produkttyp: "produkte",
                                anbieter: anbieter,
                                jahr: jahr,
                                lieferintervall: lieferintervall,
                                produkt: produkt
                            })
                        })
                    }

                    _p_anbieter_jahr.children.push(_p_anbieter_jahr_lieferintervall)
                }

                _p_anbieter.children.push(_p_anbieter_jahr)
            }
            produkte.push(_p_anbieter)
        }

        this.FrontendProductstructure = [
            {
                name: "Standard",
                children: basisprodukte,
                id: pId++
            },
            {
                name: "Produkte",
                children: produkte,
                id: pId++
            }
        ]
    },
    watch: {
        active(newValue) {
            this.activeParsed = JSON.parse(newValue)
            this.fetchProductAufschlaege(newValue)
        }
    }
}
</script>

<style>
</style>